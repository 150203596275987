






















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'MenuItem'
})
export default class InsMenuItem extends Vue {
  @Prop() private item!: object;
  @Prop() private textColor!: string;
  @Prop({ default: 1 }) private level!: number;

  showMemNav: boolean = false;

  closeSlideMenu () {
    this.$store.dispatch('isShowMenu', false);
  }

  toUrl (url) {
    if (url) {
      window.location.href = url;
    }
  }

  To (n) {
    return n.Type === 1 ? '/cms/catDetail/' + n.Value.Id : n.Type === 2 ? '/CMS/content/' + n.Value.Id : n.Type === 3 ? '/RegNPay/Form/' + n.Value.Id : (n.Type === 4 && n.Value.Id) ? '/product/cat/' + n.Value.Id : n.Type === 5 ? '/product/search/+?attrs=' + JSON.stringify([{ Id: parseInt(n.Value.Id), Vals: [] }]) + '&type=0' : '';
  }
}
